<template>
  <div>
    <b-container class="mt-4">
      <b-form @submit="sendPersonel">
        <b-card header-class="header">
          <template #header>
            <label
              v-text="'Görevli Personel Gönder'"
              class="mr-2 text-white"
            ></label>
            <b-button @click="$router.back()" class="mr-4"> Geri </b-button>
            <b-button type="submit" variant="primary"> Gönder </b-button>
          </template>
          <InputTile label="Cinsiyeti" custom>
            <b-select
              v-model="selectedGender"
              :options="genderOptions"
              @change="fetchPersonel"
            ></b-select>
          </InputTile>
          <InputTile label="Bölgesi" custom>
            <b-select
              v-model="selectedRegion"
              :options="cityOptions"
              @change="fetchPersonel"
            ></b-select>
          </InputTile>
          <InputTile label="Kişi Rolü" custom>
            <b-select
              v-model="selectedRole"
              :options="roleOptions"
              @change="fetchPersonel"
            ></b-select>
          </InputTile>
          <b-row class="mt-4">
            <b-col>
              <b-table
                :fields="fields"
                :items="
                  competitionPersonel.slice(
                    0,
                    Math.ceil(competitionPersonel.length / 2)
                  )
                "
                @row-selected="onLeftSelected"
                small
                selectable
              >
                <template #cell(id)="row">
                  <b-badge variant="secondary" class="user-select-none">
                    {{ row.index + 1 }}
                  </b-badge>
                </template>
                <template #cell(nameSurname)="row">
                  <span class="user-select-none">
                    {{ row.item.name + " " + row.item.surname }}
                  </span>
                </template>
                <template #cell(rank)="row">
                  <span class="text-danger user-select-none">
                    {{ row.item.branch }}
                  </span>
                </template>
              </b-table>
            </b-col>
            <b-col>
              <b-table
                :fields="fields"
                v-if="competitionPersonel.length > 1"
                :items="
                  competitionPersonel.slice(
                    Math.ceil(competitionPersonel.length / 2),
                    competitionPersonel.length
                  )
                "
                @row-selected="onRightSelected"
                small
                selectable
              >
                <template #cell(id)="row">
                  <b-badge variant="secondary" class="user-select-none">
                    {{
                      Math.ceil(competitionPersonel.length / 2) + row.index + 1
                    }}
                  </b-badge>
                </template>
                <template #cell(nameSurname)="row">
                  <span class="user-select-none">
                    {{ row.item.name + " " + row.item.surname }}
                  </span>
                </template>
                <template #cell(rank)="row">
                  <span class="text-danger user-select-none">
                    {{ row.item.branch }}
                  </span>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </b-container>
  </div>
</template>


<script>
import participantType from "@/store/constants/participantType";
import roles from "@/store/constants/roleOptions";
import cities from "@/store/constants/cities";
import { genderOptions } from "@/store/constants/personel";
import { mapGetters } from "vuex";
import InputTile from "@/components/forms/common/InputTile.vue";
export default {
  components: { InputTile },
  data() {
    return {
      roleOptions: roles.filter(
        (e) => !participantType.find((el) => el.text == e.text)
      ).filter((e)=> e.value != "individual"),
      cityOptions: cities.map((e) =>
        e.text == "Seçin" ? { text: "Tümü", value: "" } : e
      ),
      genderOptions,
      selectedRole: "",
      selectedGender: "",
      selectedRegion: "",
      fields: [
        { label: "Sıra", key: "id" },
        { label: "Ad Soyad", key: "nameSurname" },
        { label: "Branş", key: "rank" },
      ],
      selected: { left: [], right: [] },
    };
  },
  mounted() {
    this.$store.dispatch("initSingleCompetition", this.$route.params.id);
    this.$store.commit("updateCompetitionPersonel",[])
  },
  computed: {
    ...mapGetters({
      singleCompetition: "getSingleCompetition",
      competitionPersonel: "getCompetitionPersonel",
    }),
  },
  methods: {
    fetchPersonel() {
      if (this.selectedRole && this.selectedGender) {
        this.$store.dispatch("initCompetitionPersonel", {
          role: this.selectedRole,
          gender: this.selectedGender,
          region: this.selectedRegion,
          competitionId: this.$route.params.id,
        });
      }
    },
    sendPersonel(event) {
      event.preventDefault();
      const items = this.selected.left.concat(this.selected.right)
      if(items.length){

        this.$store.dispatch("addCompetitionGroup", {
          clubId: 0,
          items: items.map((e) => {
            return {
              personelId1: e.id,
              competitionId: this.$route.params.id,
              formOfParticipation: this.selectedRole
            }
          }),
        });
        this.$router.push({
          name: "FederationGroups",
          params: {
            competitionId: this.$route.params.id,
          },
        });
      }else{
        this.$store.dispatch("notification/setNotifications", [{ title: "Hata!", body: "Lütfen göndereceğiniz personelleri seçin", type: "danger" }]);
      }
    },
    onLeftSelected(tableItems) {
      this.selected.left = tableItems;
    },
    onRightSelected(tableItems) {
      this.selected.right = tableItems;
    },
    checkSportDuplicate() {
      return !this.items.every((e) => {
        const personels = [];
        for (let i = 1; i < 5; i++) {
          if (e["personelId" + i]) {
            personels.push(e["personelId" + i]);
          }
        }
        return personels.length == new Set(personels).size;
      });
    },
  },
};
</script>


<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>